<template>
  <article class="tariffItem" :class="{ only: tariffIndex === 3 }">
    <div class="tariffItem__title">
      <h2>
        {{ tariffName }}
        {{ tariffItem.name }}
      </h2>
    </div>

    <div class="tariffItem__speed">
      <div v-if="tariffIndex !== 3" class="speed__container">
        <div class="speed__block">
          <div :class="{ active: !switchDay }" class="speed__title">
            <h3>
              {{ tariffItem.daytime_speed_one }}
            </h3>
            <h5>
              {{ $t("tariff.mbs") }}
            </h5>
          </div>

          <div :class="{ active: switchDay }" class="speed__title">
            <h3>
              {{ tariffItem.daytime_speed_two }}
            </h3>
            <h5>
              {{ $t("tariff.mbs") }}
            </h5>
          </div>
        </div>
        <h3 class="speed__container-speed">00:00 - 18:00</h3>
      </div>
      <div class="speed__container">
        <div class="speed__block">
          <div class="speed__title">
            <h3>
              {{ tariffItem.nighttime_speed }}
            </h3>
            <h5>
              {{ $t("tariff.mbs") }}
            </h5>
          </div>
        </div>
        <h3 v-if="tariffIndex !== 3" class="speed__container-speed">
          18:00 - 00:00
        </h3>

        <h3 v-if="tariffIndex === 3" class="speed__container-speed">
          00:00 - 00:00
        </h3>
      </div>
    </div>

    <div class="select__speed">
      <h4>
        {{ $t("tariff.speed") }}
      </h4>

      <div class="selector">
        <div class="selector__item">
          <h3 v-if="tariffIndex !== 3">
            {{ tariffItem.daytime_speed_one }}
          </h3>
          <h3 v-if="tariffIndex == 3">
            {{ tariffItem.daytime_speed }}
          </h3>
          <h5>
            {{ $t("tariff.mbs") }}
          </h5>
        </div>
        <div class="selector__switcher">
          <vs-switch v-model="switchDay" v-if="tariffIndex !== 3"> </vs-switch>
          <vs-switch v-model="switchDaya" v-if="tariffIndex == 3"> </vs-switch>
        </div>

        <div class="selector__item">
          <h3 v-if="tariffIndex !== 3">
            {{ tariffItem.daytime_speed_two }}
          </h3>
          <h3 v-if="tariffIndex == 3">
            {{ tariffItem.nighttime_speed }}
          </h3>
          <h5>
            {{ $t("tariff.mbs") }}
          </h5>
        </div>
      </div>
    </div>

    <div class="subscribe">
      <h3>
        {{ $t("tariff.subscribe") }}
      </h3>

      <h4>
        <vs-switch v-model="subscribe3"> </vs-switch>
        3 {{ $t("tariff.month") }} - 5% - {{ musk(calcPrice(3, 0.05)) }}
        {{ $t("tariff.sum") }}
      </h4>

      <h4>
        <vs-switch v-model="subscribe6"> </vs-switch>
        6 {{ $t("tariff.month") }} - 15% - {{ musk(calcPrice(6, 0.15)) }}
        {{ $t("tariff.sum") }}
      </h4>

      <h4>
        <vs-switch v-model="subscribe12"> </vs-switch>
        12 {{ $t("tariff.month") }} - 30% - {{ musk(calcPrice(12, 0.3)) }}
        {{ $t("tariff.sum") }}
      </h4>
    </div>

    <div class="price">
      <h2 v-if="!changedPrice && !switchDay && !switchSubscribe">
        {{ musk(tariffItem.price_one) }}
      </h2>
      <h2 v-if="!changedPrice && switchDay && !switchSubscribe">
        {{ musk(tariffItem.price_two) }}
      </h2>
      <h2 v-if="changedPrice">
        {{ musk(changedPrice) }}
      </h2>

      <h4>{{ $t("tariff.sum") }}/{{ $t("tariff.month") }}</h4>
    </div>

    <div class="additions">
      <div class="additions__item">
        <div class="additions__item-title">
          <h5>
            {{ $t("tariff.additions.first.title") }}
          </h5>
        </div>
        <div class="additions__item-body">
          <h6>
            {{ $t("tariff.additions.first.body") }}
          </h6>
        </div>
      </div>
      <div class="additions__item">
        <div class="additions__item-title">
          <h5>
            {{ $t("tariff.additions.second.title") }}
          </h5>
        </div>
        <div class="additions__item-body">
          <h6>
            {{ $t("tariff.additions.second.body") }}
          </h6>
        </div>
      </div>
      <div class="additions__item">
        <div class="additions__item-title">
          <h5>Optimedia</h5>
        </div>
        <div class="additions__item-body">
          <h6>
            {{ tariffItem.tv }}
          </h6>
        </div>
      </div>
    </div>
    <div class="connect">
      <button class="btn" data-cursor-hover @click="sendTicket()">
        {{ $t("tarifBlock.connect") }}
      </button>
    </div>
  </article>
</template>

<script>
export default {
  data: function () {
    return {
      switchDay: false,
      switchSubscribe: false,
      subscribe3: false,
      subscribe6: false,
      subscribe12: false,
      changedPrice: this.$props.tariffItem.price_one,
      selectedTariff: {
        price: 0,
        name: "",
        daytimeSpeed: 0,
        period: "",
      },
    };
  },
  props: {
    tariffItem: {
      type: Array,
    },
    tariffName: {
      type: Array,
    },
    tariffIndex: {
      type: Number,
    },
  },
  methods: {
    calcPrice(month, sale) {
      if (!this.switchDay) {
        return (
          this.$props.tariffItem.price_one * month -
          this.$props.tariffItem.price_one * month * sale
        );
      } else {
        return (
          this.$props.tariffItem.price_two * month -
          this.$props.tariffItem.price_two * month * sale
        );
      }
    },
    clearFiled() {
      this.subscribe12 = false;
      this.subscribe6 = false;
      this.subscribe3 = false;
      this.switchSubscribe = false;
    },

    collectData() {
      const tariff = this.selectedTariff;
      const tariffProps = this.$props.tariffItem;

      if (this.subscribe3) {
        this.selectedTariff.period = 3;
      } else if (this.subscribe6) {
        this.selectedTariff.period = 6;
      } else if (this.subscribe12) {
        this.selectedTariff.period = 12;
      } else {
        this.selectedTariff.period = 1;
      }

      tariff.price = this.changedPrice;
      tariff.name = `${this.$props.tariffName} ${tariffProps.name}`;
      if (!this.switchDay) {
        tariff.daytimeSpeed = tariffProps.daytime_speed_one;
      } else {
        tariff.daytimeSpeed = tariffProps.daytime_speed_two;
      }
    },

    sendTicket() {
      this.collectData();

      console.log(this.selectedTariff);
      this.$emit("sendTicket", this.selectedTariff);
    },

    musk(val) {
      return val
        .toString()
        .split("")
        .map((num, i, arr) => {
          return (arr.length - 1 - i) % 3 === 0 && i !== arr.length - 1
            ? num + " "
            : num;
        })
        .join("");
    },
  },
  mounted() {
    this.$watch("subscribe6", function () {
      if (this.subscribe3 || this.subscribe12) {
        this.clearFiled();
      }
      if (this.subscribe6 === true) {
        this.subscribe12 = false;
        this.subscribe3 = false;

        // calculation
        this.changedPrice = this.calcPrice(6, 0.15) / 6;
      } else {
        this.switchSubscribe = false;
        this.subscribe6 = false;
        this.changedPrice = false;
      }
    });
    this.$watch("subscribe12", function () {
      if (this.subscribe3 || this.subscribe6) {
        this.clearFiled();
      }
      if (this.subscribe12 === true) {
        this.switchSubscribe = true;
        this.subscribe6 = false;
        this.subscribe3 = false;

        // calculation
        this.changedPrice = this.calcPrice(12, 0.3) / 12;
      } else {
        this.switchSubscribe = false;
        this.subscribe12 = false;
        this.changedPrice = false;
      }
    });

    this.$watch("subscribe3", function () {
      if (this.subscribe6 || this.subscribe12) {
        this.clearFiled();
      }
      if (this.subscribe3 === true) {
        this.switchSubscribe = true;
        this.subscribe6 = false;
        this.subscribe12 = false;

        // calculation
        this.changedPrice = this.calcPrice(3, 0.05) / 3;
      } else {
        this.switchSubscribe = false;
        this.subscribe3 = false;
        this.changedPrice = false;
      }
    });
    this.$watch("switchDay", function () {
      if (!this.switchDay) {
        this.changedPrice = this.$props.tariffItem.price_one;
      } else {
        this.changedPrice = this.$props.tariffItem.price_two;
      }
    });
  },
};
</script>
<style lang="scss">
.tariff-block-new {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.tariffItem {
  background: #fff;
  @media screen and (max-width: 500px) {
    padding: 1rem;
  }
  padding: 2rem;
  border-radius: 2.5rem;
  display: flex;
  flex-direction: column;

  gap: 15px;

  &.only {
    .tariffItem__speed {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__title {
    h2 {
      font-size: 1.75rem;
      font-weight: bold;
      text-align: center;
    }
  }

  &__speed {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;
    justify-content: space-between;
  }

  .select__speed {
    h4 {
      font-size: 1rem;
      text-align: center;
    }

    .selector {
      display: grid;
      justify-items: center;
      gap: 30px;
      grid-template-columns: repeat(3, minmax(0, 1fr));

      &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h3 {
          font-size: 1.75rem;
          font-weight: bold;
          margin: -5px;
        }

        h5 {
          font-size: 0.75rem;
          margin: 0;
        }
      }

      &__switcher {
        display: flex;
        align-items: center;
      }
    }
  }
}

.speed__container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &:last-child {
    .speed__block {
      align-items: center !important;
      justify-content: center;

      &::after {
        content: "";
        display: none;
      }

      .speed__title {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center !important;
        align-items: center !important;

        h3 {
          color: #000;
          font-size: 2.5rem;
        }

        h5 {
          color: #000;
        }
      }
    }
  }

  .speed__block {
    border: 0.2rem solid #f2f3f7;
    width: 150px;
    height: 150px;
    padding: 0.625rem;
    border-radius: 2.5rem;
    position: relative;
    overflow: hidden;
    @media screen and (max-width: 500px) {
      width: 100%;
    }

    &::after {
      content: "";
      width: 0.2rem;
      height: 150%;
      transform: rotate(45deg);
      top: -25%;
      right: 50%;
      position: absolute;
      background-color: #f2f3f7;
      border-radius: 2.5rem;
    }

    .speed__title {
      display: flex;
      flex-direction: column;
      justify-items: center;
      position: absolute;
      top: 0;
      left: 0;
      padding: 0.625rem;
      padding-left: 1rem;
      color: #f2f3f7;

      &.active {
        opacity: 1;
        color: #5c67ff;
      }

      &:last-child {
        right: 0;
        bottom: 0;
        justify-content: flex-end;
        text-align: right;
        padding-right: 1rem;
      }

      h3 {
        font-size: 1.5rem;
        font-weight: bold;
        margin: -5px;
        width: auto;
      }

      h5 {
        font-size: 0.5rem;
        font-size: 0.7rem;
        margin: 0;
      }
    }
  }

  &-speed {
    margin-top: 5px;
    font-size: 0.75rem;
    text-align: center;
    max-width: 150px;
  }
}

.additions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__item {
    max-width: 350px;
    margin-bottom: 15px;

    &-title {
      h5 {
        font-size: 0.75rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 0;
      }
    }

    &-body {
      h6 {
        font-size: 0.75rem;
        text-align: center;
        margin-bottom: 0;
      }
    }
  }
}

.subscribe {
  h3 {
    display: flex;
    gap: 10px;
    font-size: 1rem;
    justify-content: center;
    font-weight: bold;
  }

  h4 {
    display: flex;
    gap: 10px;
    font-size: 1rem;
    align-items: center;
  }
}

.price {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: -10px;
    font-family: Orbitron, sans-serif;
  }

  h4 {
    font-size: 1rem;
    margin-bottom: 0;
  }
}

.connect {
  button {
    width: 100%;
    justify-content: center;
  }
}

.form-switch {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
}

.form-switch i {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 30px;
  background-color: #f2f3f7;
  border-radius: 23px;
  vertical-align: text-bottom;
  transition: all 0.3s linear;
}

.form-switch i::before {
  content: "";
  position: absolute;
  left: 0;
  width: 98px;
  height: 25px;
  background-color: #f2f3f7;
  border-radius: 11px;
  transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
  transition: all 0.25s linear;
}

.form-switch i::after {
  content: "";
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 11px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
  transform: translate3d(4px, 4px, 0);
  transition: all 0.2s ease-in-out;
}

.form-switch:active i::after {
  width: 28px;
  transform: translate3d(4px, 4px, 0);
}

.form-switch:active input:checked + i::after {
  transform: translate3d(16px, 4px, 0);
}

.form-switch input {
  display: none;
}

.form-switch input:checked + i {
  background-color: #5c67ff;
}

.form-switch input:checked + i::before {
  transform: translate3d(90px, 4px, 0) scale3d(0, 0, 0);
}

.form-switch input:checked + i::after {
  transform: translate3d(76px, 4px, 0);
}
</style>
