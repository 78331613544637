<template>
  <section class="slider">

    <div class="container-fluid">


      <div class="swiper banner">
        <div class="swiper-wrapper">
          <div v-for="(bannerItem, index) in bannerArray" :key="index" class="slider__block swiper-slide">
            <article class="slider__info">
              <h2>
                {{ bannerItem.name }}
              </h2>
              <p>
                {{ bannerItem.text }}
              </p>
              <div class="slider__info-btn">
                <a v-show="bannerItem.button_name!==null" :href="bannerItem.url" class="btn" target="_blank">
                  {{ bannerItem.button_name }}
                </a>
              </div>
            </article>
            <article class="slider__poster">
              <img :src=bannerItem.poster alt="" class="img-fluid">

            </article>
          </div>


        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>

  </section>
</template>

<script>
import Swiper, {Navigation, Pagination} from "swiper";
import axios from "axios";

export default {
  name: "bigSlider",
  data: function () {
    return {
      bannerArray: []
    }
  },

  mounted() {

    new Swiper(".banner", {
      modules: [Navigation, Pagination],
      slidesPerView: 1,
      spaceBetween: 10,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },

    });

  },
  async created() {
    const lang = localStorage.getItem('lang')


    const banner = await axios.get('https://admin.optikom.uz/api/v1/web/getBanners', {
      headers: {
        lang: lang,
        page: this.$route.name
      }
    }, {});
    this.bannerArray = banner.data.data


  }
}
</script>

<style lang="scss" scoped>
@import "src/scss/vars";

.slider {
  background-color: $colorBlue;


  &__block {
    display: flex;
    align-items: center;
    position: relative;
    min-height: 500px;
  }

  &__info {
    padding: 40px 0;
    display: flex;
    //align-items: center;
    flex-direction: column;

    &-btn {
      a {
        border: 1px solid $colorWhite !important;
      }

      margin-top: 15px;
    }
  }

  &__poster {
    //position: relative;
    display: flex;
    justify-content: flex-end;

    img {
      width: 100%;
    }
  }


  article {
    width: 50%;


    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 2.8rem;
      line-height: 120%;
      letter-spacing: -0.025em;
      max-width: 600px;
      color: $colorWhite
    }

    p {
      font-weight: 300;
      font-size: 1.2rem;
      line-height: 130%;
      max-width: 600px;
      color: $colorWhite
    }
  }
}

@media screen and (max-width: 1000px) {
  .slider {
    article {
      width: auto !important;
    }
  }

}

@media screen and (max-width: 1000px) {
  .slider {
    padding: 0 !important;

    article {
      width: auto !important;
    }
  }
  .slider__block {
    flex-direction: column;

    .slider__info {
      h2 {
        font-size: 1.8rem;
        text-align: center;
      }

      p {
        font-size: 1rem;
        text-align: center;
      }

      &-btn {
        display: flex;
        justify-content: center;
      }
    }

    .slider__poster {
      display: flex;
      justify-content: center;

      img {
        position: static !important;
        max-width: 600px;
      }
    }
  }

}

</style>